import React from 'react';

export default () => (

    <React.Fragment>
        <footer id="main-footer">
            Copyright &copy; 2019
        </footer>
    </React.Fragment>

);